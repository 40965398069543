import { PAGES } from "@libs/web/urls";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as EmailValidator from "email-validator";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { checkPasswordStrength } from "../../libs/password";
import EmailField from "./EmailField";
import LoginButton from "./LoginButton";
import PasswordErrors from "./PasswordErrors";
import PasswordField from "./PasswordField";

interface EmailLoginProps {
  isSignUp: boolean;
  onSubmit: Function;
  setTermsFunction?: Function;
}

const legalLinks = [
  { label: "terms of service", url: "https://redsift.com/terms" },
  { label: "privacy policy", url: "https://redsift.com/privacy" },
  { label: "cookie policy", url: "https://redsift.com/cookies" },
];

const LegalLink = ({ href, label, last }: any) => (
  <span style={{ color: "#7f7f7f" }}>
    <a
      style={{
        color: "#4285F4",
        fontSize: "0.75rem",
        textDecorationColor: "#4285F4",
      }}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {label}
    </a>
    {last ? "." : ", "}
  </span>
);

const EmailLogin = ({
  isSignUp,
  onSubmit,
  setTermsFunction,
}: EmailLoginProps) => {
  const router = useRouter();
  const { user = "" } = router.query;
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [email, setEmail] = useState(user.toString());
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);

  const setTermsAndConditions = (terms: boolean) => {
    setTerms(terms);
    if (setTermsFunction) {
      setTermsFunction(terms);
    }
  };

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        onSubmit(email.trim(), password, terms);
      }}
    >
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <EmailField
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            onChange={(e: any) => {
              const errors = checkPasswordStrength(e.target.value);
              setPasswordErrors(errors);
              setPassword(e.target.value);
            }}
            value={password}
            error={
              password.length === 0 || (isSignUp && passwordErrors.length > 0)
            }
          />
        </Grid>
        {isSignUp && passwordErrors.length > 0 && (
          <Grid item xs={12}>
            <PasswordErrors errors={passwordErrors} />
          </Grid>
        )}
        {!isSignUp && (
          <Grid item xs={12}>
            <Typography variant="caption">
              <Link
                href={{
                  pathname: PAGES.forgotpassword,
                  query: {
                    ...router.query,
                  },
                }}
              >
                Forgot password?
              </Link>
            </Typography>
          </Grid>
        )}
        {isSignUp && (
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              inputProps={{ "aria-label": "Terms and Conditions" }}
              onClick={(e) =>
                setTermsAndConditions((e.target as HTMLInputElement).checked)
              }
            />
            <Typography
              sx={{ color: "#7f7f7f" }}
              variant="caption"
              lineHeight={"0.5"}
            >
              I have read and agree to the{" "}
              {legalLinks.map((l, i) => (
                <LegalLink
                  key={i}
                  href={l.url}
                  label={l.label}
                  last={i === legalLinks.length - 1}
                />
              ))}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <LoginButton
            disabled={
              isSignUp
                ? email === "" ||
                  !EmailValidator.validate(email?.trim()) ||
                  passwordErrors.length > 0 ||
                  !terms
                : email === "" ||
                  !EmailValidator.validate(email?.trim()) ||
                  password.length === 0
            }
            type="submit"
            provider="email"
            isSignUp={isSignUp}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default EmailLogin;
