import LoginMarketingCard from "@components/common/LoginMarketingCard";
import SplitScreen from "@components/common/SplitScreen";
import React from "react";
import LoginCard from "./LoginCard";
import SignupCard from "./SignupCard";
import styled from "styled-components";
import {Flexbox, Heading, Spinner} from "@redsift/design-system";

const Article = styled.article`
  position: relative;
`;


const LoginPage = ({
  isSignUp = false,
  setPendingCredential,
  isLoading = false,
}: {
  isSignUp: boolean;
  setPendingCredential: Function;
  isLoading?: boolean;
}) => {
  let RightPanel = isSignUp ? (
    <SignupCard />
  ) : (
    <LoginCard setPendingCredential={setPendingCredential} />
  );
  if (isLoading) {
    RightPanel = (
      <Flexbox justifyContent="center" alignItems="center" flexDirection="column" paddingTop="120px">
        <Spinner size="large" />
        <Heading as="h3">Logging in...</Heading>
      </Flexbox>
    );
  }
  return (
    <Article>
      <SplitScreen
        LeftPanel={<LoginMarketingCard isSignUp={isSignUp} />}
        RightPanel={RightPanel}
      />
    </Article>
  );
};

export default LoginPage;
