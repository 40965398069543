const config = {
  minLength: 8,
  maxLength: 64,
  minPhraseLength: 20,
};

export type PasswordGuide = {
  password: string;
  errors: string[];
};

export const checkPasswordStrength = (
  password: string
): string[] => {
  // Per: https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html
  const errors = [];
  if (password.length < config.minLength) {
    errors.push(
      `At least ${config.minLength} characters long`
    );
  }

  if (password.length > config.maxLength) {
    errors.push(
      `Fewer than ${config.maxLength} characters long`
    );
  }

  if (password.length < config.minPhraseLength) {
    if (!/[a-z]/.test(password)) {
      errors.push(
        "Contain at least one lowercase letter. [a-z]"
      );
    }

    if (!/[A-Z]/.test(password)) {
      errors.push(
        "Contain at least one uppercase letter. [A-Z]"
      );
    }

    if (!/[0-9]/.test(password)) {
      errors.push("Contain at least one number.");
    }

    if (!/[^A-Za-z0-9]/.test(password)) {
      errors.push("Contain at least one special character.");
    }
  }

  return errors;
};
