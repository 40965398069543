import { FirebaseError } from "@firebase/util";
import { PAGES } from "@libs/web/urls";
import type { GridSize } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  fetchSignInMethodsForEmail,
  getAuth,
  OAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import log from "loglevel";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useContext, useEffect } from "react";
import { useQueryClient } from "react-query";

import { hash } from "@libs/api/hash-utils/hash";
import { FEDERATED_LOGIN_PROVIDERS } from "@libs/constants";
import { signUserIn } from "@libs/firebase/client-sdk-utils";
import { getProductName } from "@libs/products";
import { AlertContext } from "../contexts/AlertContext";
import { AppContext } from "../contexts/AppContext";
import EmailLogin from "./EmailLogin";
import LoginButton from "./LoginButton";

const LoginCard = ({
  setPendingCredential,
}: {
  setPendingCredential: Function;
}) => {
  const ctx = useContext(AppContext);
  const ctxAlert = useContext(AlertContext);
  const auth = getAuth();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { tenantId = "", user = "", provider = "" } = router.query;
  // Providers
  const providers = FEDERATED_LOGIN_PROVIDERS(tenantId.toString());
  const providerLogIn = useCallback(
    async (provider: any) => {
      const { user, error } = await signUserIn(
        auth,
        provider,
        tenantId?.toString()
      );
      if (error) {
        if (error.code === "auth/account-exists-with-different-credential") {
          let email = error.customData.email;
          const pCredential: any = OAuthProvider.credentialFromError(error);
          setPendingCredential(pCredential);
          fetchSignInMethodsForEmail(auth, email).then((methods) => {
            if (methods[0] === "password") {
              ctx.setLoading(false);
              ctxAlert.setAlert(
                "Please log in using the same email address and password so that we can confirm and link your identity."
              );
            }
          });
        } else {
          ctx.setLoading(false);
          ctxAlert.setAlert((error as FirebaseError).code, "error");
        }
      }
    },
    [auth, tenantId, setPendingCredential, ctx, ctxAlert]
  );
  useEffect(() => {
    const authFunc = async () => {
      ctx.setLoading(true);
      const providerObject: any = providers.find((p) => p.value === provider);
      const instance: any = providerObject?.get().setCustomParameters({
        login_hint: user.toString(),
      });
      const resp = await signInWithPopup(auth, instance);
      return resp;
    };
    if (user || provider) {
      // Need this logic to control if user in query param is different that the one already signin
      // to signout and clear cookie from other user
      const unregisterAuthObserver = onAuthStateChanged(
        auth,
        async (userFirebase) => {
          if (userFirebase && user && user !== userFirebase.email) {
            auth
              .signOut()
              .then(async () => {
                // Clear jwt cookie
                await fetch("/api/logout");
                await router.replace({
                  pathname: PAGES.login,
                  query: {
                    ...router.query,
                  },
                });
              })
              .finally(() => {
                // clear all the queries and info from cache
                queryClient.removeQueries();
                ctx.setLoading(false);
              });
          } else if (provider) {
            authFunc();
          }
          unregisterAuthObserver();
        }
      );
    }
  });

  return (
    <Grid container rowSpacing={1} sx={{ maxWidth: "456px" }}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: 24,
            lineHeight: 2,
          }}
        >
          Log in to {getProductName(ctx.getProduct())}
        </Typography>
      </Grid>

      <Grid container item xs={12} rowSpacing={1} columnSpacing={1}>
        {providers.map((provider, i) => {
          const { value, enableLogin } = provider;
          if (!enableLogin) {
            return null;
          }
          return (
            <Grid
              key={i}
              item
              xs={12}
              lg={(12 / (providers.length - 2)) as GridSize}
            >
              <LoginButton
                provider={value}
                isSignUp={false}
                onClick={async () => {
                  ctx.setLoading(true);
                  await providerLogIn(provider.get());
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      {!tenantId && (
        <Grid container item xs={12}>
          <React.Fragment>
            <Grid item xs={12}>
              <Typography
                sx={{ textAlign: "center", color: "#7f7f7f" }}
                variant="caption"
                display="block"
                gutterBottom
              >
                {"or sign in using email"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <EmailLogin
                isSignUp={false}
                onSubmit={async (email: string, password: string) => {
                  try {
                    const hashPass = await hash(password);
                    ctx.setLoading(true);
                    const creds = await signInWithEmailAndPassword(
                      auth,
                      email,
                      hashPass
                    );
                  } catch (error) {
                    ctx.setLoading(false);
                    let msg = (error as FirebaseError).code;
                    log.debug("LoginCard::error:", error);
                    if (msg === "auth/wrong-password") {
                      msg = `Incorrect username or password. Please follow the "forgot password" or "sign up" links below.`;
                      const loginMethods = await fetchSignInMethodsForEmail(
                        auth,
                        email
                      );
                      if (!loginMethods.includes("password")) {
                        const provider = loginMethods.includes("google.com")
                          ? "Google"
                          : loginMethods.includes("microsoft.com")
                          ? "Microsoft"
                          : "";
                        if (provider) {
                          msg = `To sign in with this email address, please use the "Sign in with ${provider}" button.`;
                        }
                      }
                    } else if (msg === "auth/user-not-found") {
                      msg = `Incorrect username or password. Please follow the "forgot password" or "sign up" links below.`;
                    }
                    ctxAlert.setAlert(msg, "error");
                  }
                }}
              />
            </Grid>
          </React.Fragment>
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <Typography variant="body2">
          Not yet a user?{" "}
          <b>
            <Link
              href={{
                pathname: PAGES.signup,
                query: {
                  ...router.query,
                },
              }}
            >
              Sign up
            </Link>
          </b>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginCard;
