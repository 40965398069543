import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import React, { useState } from "react";


type PasswordFieldProps = {
  onChange: any,
  value: string,
  error: boolean,
}

const PasswordField = ({ onChange, value, error } : PasswordFieldProps) => {
  const [ showPassword, setShowPassword ] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl sx={{ width: "100%" }} variant="filled">
      <InputLabel htmlFor="password-field">password</InputLabel>
      <FilledInput
        type={showPassword ? "text" : "password"}
        error={error || value.length === 0}
        id="password-field"
        value={value}
        onChange={onChange}
        style={{ borderRadius: "4px"}}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default PasswordField;
