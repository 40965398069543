import { FirebaseError } from "@firebase/util";
import { hash } from "@libs/api/hash-utils/hash";
import { FEDERATED_LOGIN_PROVIDERS } from "@libs/constants";
import { PAGES } from "@libs/web/urls";
import type { GridSize } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useContext, useState } from "react";
import { signUserIn } from "../../libs/firebase/client-sdk-utils";
import { getProductName } from "../../libs/products";
import { AlertContext } from "../contexts/AlertContext";
import { AppContext } from "../contexts/AppContext";
import EmailLogin from "./EmailLogin";
import LoginButton from "./LoginButton";

// Providers
const providers = FEDERATED_LOGIN_PROVIDERS();

const SignupCard = () => {
  const auth = getAuth();
  const ctx = useContext(AppContext);
  const ctxAlert = useContext(AlertContext);
  const router = useRouter();
  const [terms, setTerms] = useState(false);

  const providerLogIn = useCallback(
    async (provider: any) => {
      if (!terms) {
        ctxAlert.setAlert("You need to accept our legal terms.", "error");
        return;
      }
      ctx.setLoading(true);
      const { user, error } = await signUserIn(auth, provider);
      if (error) {
        ctx.setLoading(false);
        ctxAlert.setAlert((error as FirebaseError).code, "error");
      }
    },
    [ctx, ctxAlert, auth, terms]
  );

  return (
    <Grid container rowSpacing={1} sx={{ maxWidth: "456px" }}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: 24,
            lineHeight: 2,
          }}
        >
          Sign up to {getProductName(ctx.getProduct())}
        </Typography>
      </Grid>
      <Grid container item xs={12} rowSpacing={1} columnSpacing={1}>
        {providers.map((provider, i) => {
          const { value, enableSignup } = provider;
          if (!enableSignup) {
            return null;
          }
          return (
            <Grid
              key={i}
              item
              xs={12}
              lg={(12 / (providers.length - 2)) as GridSize}
            >
              <LoginButton
                provider={value}
                isSignUp={true}
                onClick={async () => await providerLogIn(provider?.get())}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid container item xs={12}>
        <React.Fragment>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: "center", color: "#7f7f7f" }}
              variant="caption"
              display="block"
              gutterBottom
            >
              {"or sign up using email"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <EmailLogin
              isSignUp={true}
              setTermsFunction={setTerms}
              onSubmit={async (
                email: string,
                password: string,
                terms: boolean
              ) => {
                if (!terms) {
                  ctxAlert.setAlert(
                    "You need to accept our legal terms.",
                    "error"
                  );
                  return;
                }
                try {
                  ctx.setLoading(true);
                  const hashPass = await hash(password);
                  await createUserWithEmailAndPassword(auth, email, hashPass);
                } catch (error) {
                  ctx.setLoading(false);
                  ctxAlert.setAlert((error as FirebaseError).code, "error");
                }
              }}
            />
          </Grid>
        </React.Fragment>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <Typography variant="body2">
          Already a user?{" "}
          <b>
            <Link
              href={{
                pathname: PAGES.login,
                query: {
                  ...router.query,
                },
              }}
            >
              Log in
            </Link>
          </b>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SignupCard;
