import { AlertContext } from "@components/contexts/AlertContext";
import { AppContext } from "@components/contexts/AppContext";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FirebaseError } from "firebase-admin";
import { getAuth, sendEmailVerification } from "firebase/auth";
import log from "loglevel";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";

interface EmailVerificationDialogProps {
  openProps: boolean;
  email: string;
  setLoginLoading?: Function
}
export default function EmailVerificationDialog({
  openProps,
  email,
  setLoginLoading,
}: EmailVerificationDialogProps) {
  const [open, setOpen] = useState(openProps);
  const auth = getAuth();
  const ctx = useContext(AppContext);
  const ctxAlert = useContext(AlertContext);
  const router = useRouter();
  useEffect(() => {
    setOpen(openProps);
  }, [openProps]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    auth.signOut().then(() => {});
  };

  const handleSendEmailVerification = () => {
    log.debug("EmailVerificationDialog::handleSendEmailVerification");
    if (auth && auth.currentUser) {
      sendEmailVerification(auth.currentUser)
        .then(() => {
          ctx.setLoading(false);
          ctxAlert.setAlert("Email verification sent", "success");
        })
        .catch((error) => {
          log.error(
            "EmailVerificationDialog::handleSendEmailVerification::sendSignInLinkToEmail",
            error
          );
          ctxAlert.setAlert((error as FirebaseError).code, "error");
        })
        .finally(() => {
          setOpen(false);
          if (setLoginLoading) {
            setLoginLoading(false);
          }
          auth.signOut().then(() => {});
        });
    } else {
      log.error(
        "EmailVerificationDialog::handleSendEmailVerification::auth.currentUser is null"
      );
      throw new Error("Problem with firebase");
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ marginButton: "1px solid red" }}>
          Email verification
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Your email is not verified, we will send you a link to verify it to
            your email address.
          </DialogContentText>
          <Box mt={2} sx={{ display: "flex", gap: 1 }}>
            <MarkEmailReadIcon color={"primary"} />
            <Typography variant="body1">{email}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSendEmailVerification}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
