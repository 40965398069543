import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import * as EmailValidator from "email-validator";

interface EmailFieldProps {
  onChange: any;
  value: string;
}

const EmailField = ({ onChange, value }: EmailFieldProps) => {
  return (
    <FormControl sx={{ width: "100%" }} variant="filled">
      <InputLabel htmlFor="email-field">email</InputLabel>
      <FilledInput
        error={value !== "" && !EmailValidator.validate(value.trim())}
        id="email-field"
        value={value}
        onChange={onChange}
        style={{ borderRadius: "4px"}}
      />
    </FormControl>
  );
};

export default EmailField;
